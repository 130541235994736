<template>
  <b-modal v-model="employeeModal" size="md"
           @ok="sendRequestForEmployee"
           @hidden="hideEmployeeModal"
           :title="employeeType === 'edit' ? $t('Employees.Edit_Modal_Title') : $t('Employees.Modal_Title')"
           :ok-title="employeeType === 'edit' ? $t('Employees.Modal_Save') : $t('Employees.Modal_Submit')"
           :cancel-title="$t('Employees.Modal_Cancel')">
    <template #modal-footer="{ ok, cancel, hide }">
      <b-button size="sm" variant="success" @click="sendRequestForEmployee"
                :disabled="employeeModalProcessing || !isValid">{{ employeeType === 'edit' ? $t('Employees.Modal_Save') : $t('Employees.Modal_Submit') }}</b-button>
      <b-button size="sm" variant="" @click="hideEmployeeModal">{{ $t('Employees.Modal_Cancel') }}</b-button>
    </template>
    <b-form>
      <div class="form-group">
        <label>{{ $t('Employees.Modal_FirstName') }} <span class="text-danger">*</span></label>
        <b-form-input
            class="form-control"
            v-model="employee.first_name"
            :disabled="employeeModalProcessing"
        ></b-form-input>
      </div>
      <div class="form-group">
        <label>{{ $t('Employees.Modal_LastName') }} <span class="text-danger">*</span></label>
        <b-form-input
            class="form-control"
            v-model="employee.last_name"
            :disabled="employeeModalProcessing"
        ></b-form-input>
      </div>
      <div class="form-group">
        <label>{{ $t('Employees.Modal_Patronymic') }}</label>
        <b-form-input
            class="form-control"
            v-model="employee.father_name"
            :disabled="employeeModalProcessing"
        ></b-form-input>
      </div>
      <div class="form-group">
        <label>{{ $t('Employees.Modal_Email') }} <span class="text-danger">*</span></label>
        <b-form-input
            class="form-control"
            v-model="employee.email"
            :disabled="employeeModalProcessing"
            type="email"
        ></b-form-input>
      </div>
      <div class="form-group">
        <label>{{ $t('Employees.Modal_Phone_Number') }} <span class="text-danger">*</span></label>
        <b-form-input
            class="form-control"
            v-model="employee.phone"
            :disabled="employeeModalProcessing"
            v-mask="'+# ### ###-##-##'"
        ></b-form-input>
      </div>
      <p class="text-danger">{{ errorMessage }}</p>
    </b-form>
  </b-modal>
</template>

<script>
import api from "@/plugins/api";

export default {
  name: "EmployeeModal",
  data() {
    return {
      employeeModal: false,
      employeeModalProcessing: false,
      employee: {
        first_name: '',
        last_name: '',
        father_name: '',
        email: '',
        phone: '',
      },
      passwordGen: null,
      errorMessage: null
    }
  },
  props: {
    showEmployeeModal: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: Number,
      default: null
    },
    employeeType: {
      type: String,
      default: null
    },
    employeeData: {
      type: Object,
      default: null
    }
  },
  computed: {
    isValid() {
      if (this.employee.first_name.length > 2 && this.employee.last_name.length > 2
          && this.validateEmail(this.employee.email) && (this.employee.phone && this.employee.phone.length === 16)) return true;
      else return false;
    }
  },
  watch: {
    showEmployeeModal(val) {
      this.employeeModal = val;
    },
    employeeData(val) {
      this.employee.first_name = val.first_name;
      this.employee.last_name = val.last_name;
      this.employee.father_name = val.father_name;
      this.employee.email = val.email;
      this.employee.phone = val.phone;
    }
  },
  methods: {
    validateEmail(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    sendRequestForEmployee() {
      this.employeeModalProcessing = true;
      this.$store.commit('user/UpdateLoaded', false);
      if (this.employeeType === 'add') {
        this.passwordGen = Math.random().toString(36).slice(-10);
        api.post('/v2/auth/register', {
          first_name: this.employee.first_name,
          last_name: this.employee.last_name,
          father_name: this.employee.father_name,
          email: this.employee.email,
          phone: this.employee.phone,
          password: this.passwordGen,
          agree: true,
          agree2: true,
          company_id: this.companyId
        }).then(() => {
          this.employeeModalProcessing = false;
          this.employeeModal = false;
          this.employee = {
            first_name: '',
            last_name: '',
            father_name: '',
            email: '',
            phone: '',
          };
          this.errorMessage = null;
          this.$store.commit('user/UpdateLoaded', true);
          this.$emit('updateEmployeeData', { employee: this.employee, type: this.employeeType, changed: true });
          this.$emit('updateShowEmployeeModal', false);
        }).catch(reason => {
          let _error = []
          try {
            if (reason.response.data.error && reason.response.data.error.extra) {
              for (let i in reason.response.data.error.extra) {
                _error.push(reason.response.data.error.extra[i][0])
              }
            }
            if (reason.response.data.error && reason.response.data.error.message) {
              _error.length === 0 ? _error.push(reason.response.data.error.message) : null;
            }
          } catch (e) {}
          this.errorMessage = _error.join('<br>')
          this.employeeModalProcessing = false;
        });
      } else {
        api.put('/v2/companies/' + this.companyId + '/staff?user_id=' + this.employeeData.id, {
          first_name: this.employee.first_name,
          last_name: this.employee.last_name,
          father_name: this.employee.father_name,
          email: this.employee.email,
          phone: this.employee.phone
        }).then(() => {
          this.employeeModalProcessing = false;
          this.employeeModal = false;
          this.employee = {
            first_name: '',
            last_name: '',
            father_name: '',
            email: '',
            phone: '',
          };
          this.errorMessage = null;
          this.$store.commit('user/UpdateLoaded', true);
          this.$emit('updateEmployeeData', { employee: this.employee, type: this.employeeType, changed: true });
          this.$emit('updateShowEmployeeModal', false);
        }).catch(reason => {
          let _error = []
          try {
            if (reason.response.data.error && reason.response.data.error.extra) {
              for (let i in reason.response.data.error.extra) {
                _error.push(reason.response.data.error.extra[i][0])
              }
            }
            if (reason.response.data.error && reason.response.data.error.message) {
              _error.length === 0 ? _error.push(reason.response.data.error.message) : null;
            }
          } catch (e) {}
          this.errorMessage = _error.join('<br>')
          this.employeeModalProcessing = false;
        });
      }
    },
    hideEmployeeModal() {
      this.employeeModal = false;
      this.employee = {
        first_name: '',
        last_name: '',
        father_name: '',
        email: '',
        phone: '',
      };
      this.errorMessage = null;
      this.$emit('updateEmployeeData', { employee: this.employee, type: 'add', changed: false });
      this.$emit('updateShowEmployeeModal', false);
    }
  }
}
</script>

<style scoped>

</style>
